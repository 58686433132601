<template>
  <div>
    <el-carousel height="500px">
      <el-carousel-item v-for="(item,index) in bannerlist" :key="index">
        <img :src="item" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="product">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">{{ producttit }}</div>
          <div class="tit2"></div>
        </div>
        <!-- v-html="productcon" -->
        <div class="product_con">梦宇3D将以先进的技术、一流的质量和精湛的产品和为广大客户提供满意的产品和服务</div>
      </div>
      <div class="product_card">
        <ul>
          <li v-for="(item, index) in productlist" :key="index">
            <div class="card">
              <div class="card_info">
                <div class="card_tit">{{ item.articleTitle }}</div>
                <div class="card_con" v-html="item.articleContent"></div>
              </div>
              <div class="more">
                <span class="more_tit" @click="learnmore(item.path)">了解更多</span><img src="../assets/images/01首页/箭头.png" alt="" class="more_img" />
              </div>
              <div class="card_img">
                <img :src="'http://36.152.65.166:10008'+item.articleCover" alt="" />
              </div>
            </div>
          </li>
          <!-- <li>
            <div class="card">
              <div class="card_info">
                <div class="card_tit">数字医教</div>
                <div class="card_con">
                  运用先进的运动捕捉技术，为数字影视、数字动画、数字游戏、数字广告等提供高质量、高效率、低成本的3D数字内容制作
                </div>
              </div>
              <div class="more">
                <span class="more_tit">了解更多</span
                ><img
                  src="../assets/images/01首页/箭头.png"
                  alt=""
                  class="more_img"
                />
              </div>
              <div class="card_img">
                <img src="../assets/images/01首页/数字医教.jpg" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="card">
              <div class="card_info">
                <div class="card_tit">VR_AR</div>
                <div class="card_con">
                  运用先进的运动捕捉技术，为数字影视、数字动画、数字游戏、数字广告等提供高质量、高效率、低成本的3D数字内容制作
                </div>
              </div>
              <div class="more">
                <span class="more_tit">了解更多</span
                ><img
                  src="../assets/images/01首页/箭头.png"
                  alt=""
                  class="more_img"
                />
              </div>
              <div class="card_img">
                <img src="../assets/images/01首页/VR_AR.jpg" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="card">
              <div class="card_info">
                <div class="card_tit">运动捕捉</div>
                <div class="card_con">
                  运用先进的运动捕捉技术，为数字影视、数字动画、数字游戏、数字广告等提供高质量、高效率、低成本的3D数字内容制作
                </div>
              </div>
              <div class="more">
                <span class="more_tit">了解更多</span
                ><img
                  src="../assets/images/01首页/箭头.png"
                  alt=""
                  class="more_img"
                />
              </div>
              <div class="card_img">
                <img src="../assets/images/01首页/运动捕捉.jpg" alt="" />
              </div>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="company">
      <div class="company_tit">公司简介</div>
      <div class="company_btn" @click="checkList">查看详情</div>
    </div>
    <!-- <div>
      <div class="cases">
        <div class="product_title">
          <div class="product_tit">
            <div class="tit1">数字文娱</div>
            <div class="tit2"></div>
          </div>
        </div>
        <div class="caselist">
          <ul class="caselistul">
            <li v-for="(item, index) in casearr[currpage - 1]" :key="index">
              <div class="caseimg">
                <img :src="item.articleCover" alt="" />
              </div>
              <div class="casetit" v-html="item.articleTitle"></div>
            </li>
          </ul>
        </div>
        <div class="pagination">
          <div class="prevbtn" @click="prevpage()"></div>
          <div class="page">
            <ul>
              <li v-for="(item, index) in pages" :key="index" :class="index + 1 === currpage ? 'hover' : ''" @click="choosepage(index)"></li>
            </ul>
          </div>
          <div class="nextbtn" @click="nextpage()"></div>
        </div>
      </div>
    </div>
    <div class="xuni">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">虚拟仿真</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="caselist">
        <ul class="caselistul">
          <li v-for="(item, index) in xncasearr[xncurrpage - 1]" :key="index">
            <div class="caseimg">
              <img :src="item.imgsrc" alt="" />
            </div>
            <div class="casetit">{{ item.name }}</div>
          </li>
        </ul>
      </div>
      <div class="pagination">
        <div class="prevbtn" @click="xnprevpage()"></div>
        <div class="page">
          <ul>
            <li v-for="(item, index) in xnpages" :key="index" :class="index + 1 === xncurrpage ? 'hover' : ''" @click="xnchoosepage(index)"></li>
          </ul>
        </div>
        <div class="nextbtn" @click="xnnextpage()"></div>
      </div>
    </div> -->
    <!-- <div class="news">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">新闻动态</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="newslist">
        <ul>
          <li>
            <div class="newsimg">
              <img
                src="../assets/images/03新闻动态/方彤：现实与虚拟场景“全”交互体验.png"
                alt=""
              />
            </div>
            <div class="newscon">
              <div class="newcon_tit">方彤：现实与虚拟场景“全”交互体验</div>
              <div class="newcon_txt">
                第三届全球传感器高峰论坛暨中国物联网应用峰会今天在合肥召开。以“聚集真知灼见，共谋产业发展”为主题，以“高峰论坛+展览展示+投资洽+。。。。。。。”
              </div>
            </div>
            <div class="newsinfo">
              <div class="newssee">
                <img src="../assets/images/01首页/浏览.png" alt="" />
                <span>132</span>
              </div>
              <div class="newsnice">
                <img src="../assets/images/01首页/点赞.png" alt="" />
                <span>86</span>
              </div>
              <div class="newstime">
                <img src="../assets/images/01首页/发布日期.png" alt="" />
                <span>2019-04-16</span>
              </div>
            </div>
          </li>
          <li>
            <div class="newsimg">
              <img
                src="../assets/images/03新闻动态/梦宇3D 亮相国家“十二五”科技创新成就展.png"
                alt=""
              />
            </div>
            <div class="newscon">
              <div class="newcon_tit">
                梦宇3D 亮相国家“十二五”科技创新成就展
              </div>
              <div class="newcon_txt">
                第三届全球传感器高峰论坛暨中国物联网应用峰会今天在合肥召开。以“聚集真知灼见，共谋产业发展”为主题，以“高峰论坛+展览展示+投资洽+。。。。。。。”
              </div>
            </div>
            <div class="newsinfo">
              <div class="newssee">
                <img src="../assets/images/01首页/浏览.png" alt="" />
                <span>132</span>
              </div>
              <div class="newsnice">
                <img src="../assets/images/01首页/点赞.png" alt="" />
                <span>86</span>
              </div>
              <div class="newstime">
                <img src="../assets/images/01首页/发布日期.png" alt="" />
                <span>2019-04-16</span>
              </div>
            </div>
          </li>
          <li>
            <div class="newsimg">
              <img
                src="../assets/images/03新闻动态/中影梦宇实时动作捕捉中心.png"
                alt=""
              />
            </div>
            <div class="newscon">
              <div class="newcon_tit">中影梦宇实时动作捕捉中心</div>
              <div class="newcon_txt">
                第三届全球传感器高峰论坛暨中国物联网应用峰会今天在合肥召开。以“聚集真知灼见，共谋产业发展”为主题，以“高峰论坛+展览展示+投资洽+。。。。。。。”
              </div>
            </div>
            <div class="newsinfo">
              <div class="newssee">
                <img src="../assets/images/01首页/浏览.png" alt="" />
                <span>132</span>
              </div>
              <div class="newsnice">
                <img src="../assets/images/01首页/点赞.png" alt="" />
                <span>86</span>
              </div>
              <div class="newstime">
                <img src="../assets/images/01首页/发布日期.png" alt="" />
                <span>2019-04-16</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="morenews">了解更多</div>
    </div>
    <div class="cases">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">经典案例</div>
          <div class="tit2"></div>
        </div>
        <p class="product_con">经典源自于自然的恩赐，更融合了人类的智慧。</p>
      </div>
      <div class="caselist">
        <ul>
          <li>
            <div class="caseimg">
              <img
                src="../assets/images/08经典案例/临床技能中心综合管理系统.png"
                alt=""
              />
            </div>
            <div class="casetit">临床技能中心综合管理系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img src="../assets/images/08经典案例/VR游戏.png" alt="" />
            </div>
            <div class="casetit">VR游戏</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="../assets/images/08经典案例/智能数字化病人.png"
                alt=""
              />
            </div>
            <div class="casetit">智能数字化病人</div>
          </li>
          <li>
            <div class="caseimg">
              <img src="../assets/images/08经典案例/融合投影.png" alt="" />
            </div>
            <div class="casetit">融合投影</div>
          </li>
        </ul>
      </div>
    </div> -->
    <!-- 数字文娱 -->
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">数字文娱</div>
          <div class="tit2"></div>
        </div>
      </div>

      <div class="sportswiper">
        <swiper ref="numberSwiper" :options="swiperOption1" id="myswiper">
          <swiper-slide v-for="(item,index) in casearr" :key="index">
            <div class="swiper_con">
              <img :src="item.articleCover" alt="" />
              <span>{{ item.articleTitle }}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>

        <div class="swiper-button-prev-diy" @click="numberPrevFn"></div>
        <div class="swiper-button-next-diy" @click="nmuberNextFn"></div>
      </div>
    </div>
    <!-- 虚拟仿真 -->
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">虚拟仿真</div>
          <div class="tit2"></div>
        </div>
      </div>

      <div class="sportswiper">
        <!-- {{xncasearr.length}} -->
        <swiper ref="xnSwiper" :options="swiperOption2" id="myswiper1">

          <swiper-slide v-for="(item,index) in xncasearr" :key="index">
            <div class="swiper_con" @click="routerlinkXncase(item.articleId,index)">
              <img :src="item.articleCover" alt="" />
              <span>{{item.articleTitle}}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>

        <div class="swiper-button-prev-diy" @click="xnPrevFn"></div>
        <div class="swiper-button-next-diy" @click="xnNextFn"></div>
      </div>
    </div>
    <!-- 运动捕捉 -->
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">运动捕捉</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="swiper_tit">
        <ul>
          <li v-for="(item, index) in titlist" :key="index" :class="{ blue: index === currindex }" @click="addclass(index, item.articleId)">
            <div :class="`swiper_img${index + 1}`"></div>
            <span>{{ item.articleTitle }}</span>
          </li>
        </ul>
      </div>
      <div class="sportswiper">
        <swiper ref="mySwiper" :options="swiperOption33" id="myswiper2">
          <swiper-slide v-for="(item,index) in sportlist[currindex]" :key="index">
            <div class="swiper_con" @click="routerlinkSport(currindex,item.articleTitle,index)">
              <img :src="item.articleCover" alt="" />
              <span>{{ item.articleTitle }}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>

        <div class="swiper-button-prev-diy" @click="prevFn"></div>
        <div class="swiper-button-next-diy" @click="nextFn"></div>
      </div>
    </div>
    <div class="partners">
      <div class="partners_title">
        <!-- <div class="titline"></div> -->
        <div class="partners_tit">合作伙伴<span class="friendline"></span></div>
        <!-- <div class="titline"></div> -->
      </div>
      <ul class="partners_img">
        <!-- <li class="partners_img1"></li>
        <li class="partners_img2"></li>
        <li class="partners_img3"></li>
        <li class="partners_img4"></li>
        <li class="partners_img5"></li> -->
        <li class="friendimg" v-for="(item,index) in friendlist" :key="index">
          <img :src="item" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getbannerimg, getbannerdetail } from "@/http/api/user.js";

import friend_img1 from "@/assets/images/01首页/合作伙伴/腾讯游戏.png";
import friend_img2 from "@/assets/images/01首页/合作伙伴/纵深网络.png";
import friend_img3 from "@/assets/images/01首页/合作伙伴/玩友时代.png";
import friend_img4 from "@/assets/images/01首页/合作伙伴/Igg.png";
import friend_img5 from "@/assets/images/01首页/合作伙伴/冬竞.png";
import friend_img6 from "@/assets/images/01首页/合作伙伴/中影动画.png";
import friend_img7 from "@/assets/images/01首页/合作伙伴/原力.png";
import friend_img8 from "@/assets/images/01首页/合作伙伴/舞之动画.png";
import friend_img9 from "@/assets/images/01首页/合作伙伴/叠纸暖暖.png";
import friend_img10 from "@/assets/images/01首页/合作伙伴/黑将网络.png";
import friend_img11 from "@/assets/images/01首页/合作伙伴/水晶石.png";
import friend_img12 from "@/assets/images/01首页/合作伙伴/莉莉丝游戏.png";
import friend_img13 from "@/assets/images/01首页/合作伙伴/盛大游戏.png";
import friend_img14 from "@/assets/images/01首页/合作伙伴/巨人网络2.png";
import friend_img15 from "@/assets/images/01首页/合作伙伴/利亚德.png";
import friend_img16 from "@/assets/images/01首页/合作伙伴/铁科院.png";
import friend_img17 from "@/assets/images/01首页/合作伙伴/首都医科大学护理学院.png";
import friend_img18 from "@/assets/images/01首页/合作伙伴/上海十院.png";
import friend_img19 from "@/assets/images/01首页/合作伙伴/西南医院.png";
import friend_img20 from "@/assets/images/01首页/合作伙伴/上海健康医学院.png";
import friend_img21 from "@/assets/images/01首页/合作伙伴/北京清华长庚医院.png";
import friend_img22 from "@/assets/images/01首页/合作伙伴/附件中医药大学.png";
import friend_img23 from "@/assets/images/01首页/合作伙伴/江苏医药职业学院.png";
import friend_img24 from "@/assets/images/01首页/合作伙伴/国家电网.png";
import friend_img25 from "@/assets/images/01首页/合作伙伴/首都医科大学.png";
import friend_img26 from "@/assets/images/01首页/合作伙伴/北京协和医院.png";
import friend_img27 from "@/assets/images/01首页/合作伙伴/北京体育大学.png";
import friend_img28 from "@/assets/images/01首页/合作伙伴/中国中医药出版社.png";
import friend_img29 from "@/assets/images/01首页/合作伙伴/上海九院.png";
import friend_img30 from "@/assets/images/01首页/合作伙伴/湘雅二院.png";

export default {
  data() {
    return {
      // 轮播图
      bannerlist: [],

      // 产品标题
      producttit: "",
      // 产品分类
      productlist: [],
      // 数字文娱
      casearr: [],
      // 虚拟仿真
      xncasearr: [],

      // 运动捕捉
      titlist: [],
      currindex: 0,
      sportlist: [],
      Newsportlist: [],
      // 解决方案
      solvelist: [],
      // 轮播
      swiperOption1: {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 3,

        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        // loop: true,
      },
      swiperOption2: {
        slidesPerView: 3,
        // spaceBetween: 10,
        slidesPerGroup: 3,

        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        // loop: true,
      },
      swiperOption33: {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 3,

        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        // loop: true,
      },
      // 合作伙伴
      friendlist: [
        friend_img1,
        friend_img2,
        friend_img3,
        friend_img4,
        friend_img5,
        friend_img6,
        friend_img7,
        friend_img8,
        friend_img9,
        friend_img10,
        friend_img11,
        friend_img12,
        friend_img13,
        friend_img14,
        friend_img15,
        friend_img16,
        friend_img17,
        friend_img18,
        friend_img19,
        friend_img20,
        friend_img21,
        friend_img22,
        friend_img23,
        friend_img24,
        friend_img25,
        friend_img26,
        friend_img27,
        friend_img28,
        friend_img29,
        friend_img30,
      ],
    };
  },
  watch: {},
  computed: {
    // 数字文娱
    numberSwiper() {
      return this.$refs.numberSwiper.$swiper;
    },
    // 虚拟仿真
    xnSwiper() {
      return this.$refs.xnSwiper.$swiper;
    },
    // 运动捕捉
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  created() {},
  mounted() {
    // 轮播图
    this.init();
    // 产品详情
    this.getdetail();
    // 数字文娱.
    this.getEntertainment();
    // 虚拟仿真
    this.getSimulation();
    // 运动捕捉
    this.getSport();
  },
  methods: {
    // 公司简介查看详情
    checkList() {
      this.$router.push({
        path: "/aboutus",
      });
    },
    //了解更多
    learnmore(path) {
      this.$router.push({
        path: path,
      });
    },
    //获取轮播图
    async init() {
      let params = {};
      const data = await getbannerimg(params);

      let arr = [];
      for (let i = 0; i < data.data.length; i++) {
        arr[i] = "http://36.152.65.166:10008" + data.data[i].image;
      }
      this.bannerlist = arr;
    },
    //获取产品详情
    async getdetail() {
      let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);
      var res = data.data.portalArticleList;

      this.producttit = res[0].articleTitle;

      this.productlist = res[0].children;
      this.productlist[0].path = "/entertainment/game";
      this.productlist[1].path = "/medicaleducation/info";
      this.productlist[2].path = "/sport/system";
    },
    //获取数字文娱
    async getEntertainment() {
      let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);
      this.casearr = data.data.portalArticleList[1].children;
      this.casearr.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });

      // swiper.params.slidesPerGroup = adjustedSlidesPerGroup;

      // this.casearr = data.data.portalArticleList[1];
    },
    //数字文娱分页
    numberPrevFn() {
      this.numberSwiper.slidePrev();
    },
    nmuberNextFn() {
      this.numberSwiper.slideNext();
    },
    //获取虚拟仿真
    async getSimulation() {
      let params = {
        barId: 7,
      };
      const data = await getbannerdetail(params);
      this.xncasearr = data.data.children[0].portalArticleList;

      this.xncasearr.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });
    },
    // 虚拟仿真详情页
    routerlinkXncase(id, index) {
      this.$router.push({
        path: `/medicaleducation/infodetail`,
        query: {
          id: id,
          index: index,
        },
      });
    },
    // 虚拟仿真分页
    xnPrevFn() {
      this.xnSwiper.slidePrev();
    },
    xnNextFn() {
      this.xnSwiper.slideNext();
    },
    //获取运动捕捉标题
    async getSport() {
      let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);

      this.titlist = data.data.portalArticleList[17].children;
      // console.log( data.data.children[0]);
      this.sportlist[0] = data.data.portalArticleList[18].children;
      this.sportlist[1] = data.data.portalArticleList[19].children;
      this.sportlist[2] = data.data.portalArticleList[20].children;
      for (let k = 0; k < this.sportlist.length; k++) {
        for (let t = 0; t < this.sportlist[k].length; t++) {
          let text = this.sportlist[k][t].articleCover;
          this.sportlist[k][t].articleCover =
            "http://36.152.65.166:10008" + text;
        }
      }
    },
    // 运动捕捉高亮
    addclass(index) {
      this.currindex = index;
    },
    // 运动捕捉分页
    prevFn() {
      this.swiper.slidePrev();
    },
    nextFn() {
      this.swiper.slideNext();
    },
    // 获取新的运动捕捉和新的解决方案
    async getcompany() {
      let params = {
        barId: 9,
      };
      const data = await getbannerdetail(params);
      this.Newsportlist = data.data.children[0].portalArticleList;
      this.solvelist = data.data.children[1].portalArticleList;
    },
    // 运动捕捉详情页
    async routerlinkSport(currindex, name, index) {
      if (this.Newsportlist.length == 0) {
        await this.getcompany();
      }
      if (currindex == 0) {
        this.Newsportlist.forEach((r, i) => {
          if (r.articleTitle == name) {
            this.$router.push({
              path: `/sport/system/systemdetail`,
              query: {
                id: r.articleId,
                index: i,
              },
            });
          } else {
            return;
          }
        });
      } else if (currindex == 1) {
        this.solvelist.forEach((r, i) => {
          if (r.articleTitle == name) {
            this.$router.push({
              path: `/sport/plan/plandetail`,
              query: {
                id: r.articleId,
                index: i,
              },
            });
          } else {
            return;
          }
        });
      } else {
        return;
      }
    },
  },
};
</script>

<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  padding: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel__button {
  width: 40px !important;
  height: 10px !important;
}
.el-carousel__indicator.is-active button {
  background-color: #0088ea !important;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  opacity: 1 !important;
  background-color: #cccccc !important;
}
.swiper-container {
  width: 100% !important;

  overflow: initial;
}
.swiper-pagination {
  position: absolute !important;
  bottom: -66px !important;
  z-index: 99999;
}
.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background-color: #666666 !important;
}
</style>
<style lang="scss" scoped>
.product {
  height: 580px;
  width: 100%;
  background-color: #f3f4f8;
}
.product_title {
  width: 1456px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
    .tit1 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .tit2 {
      font-size: 20px !important;
      width: 60px;
      height: 4px;
      background-color: #0088ea;
    }
  }
  p {
    font-size: 24px;
  }
}
.product_card {
  ul {
    display: flex;
    width: 1456px;
    margin: 0 auto;
    padding: 0;
    justify-content: space-between;
    align-items: center;
  }
}
.product_con {
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 24px !important;
}
.card {
  width: 468px;
  height: 396px;
  border-top: 10px solid #0088ea;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .card_img {
    height: 140px;
    img {
      height: 140px;
      width: 100%;
    }
  }
  .card_info {
    height: 140px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .card_tit {
      margin-top: 20px;
      font-size: 0.24rem;
      font-weight: bold;
    }
    .card_con {
      width: 350px;
      overflow: hidden;
      font-size: 0.18rem;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .more {
    margin-top: 60px;
    .more_tit {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      margin-right: 13px;
    }
    display: flex;
    align-items: center;
    margin-left: 60px;
  }
}
.company {
  width: 100%;
  height: 194px;
  background: url("../assets/images/01首页/公司简介.jpg") no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .company_tit {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
  }
  .company_btn {
    color: #fff;
    font-size: 24px;
    width: 240px;
    height: 70px;
    margin-top: -24px;
    background-color: #0088ea;
    text-align: center;
    line-height: 70px;
    cursor: pointer;
  }
  .company_btn:hover {
    background-color: #047acf;
  }
}
.partners {
  height: 750px;
  margin-top: -100px;
  .partners_title {
    display: flex;
    justify-content: center;

    width: 1480px;
    margin: 0 auto;
    margin-top: 126px;

    .titline {
      height: 1px;
      width: 655px;
      background-color: #d2d2d2;
    }
    .partners_tit {
      width: 100px;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin: 0 30px;

      .friendline {
        display: inline-block;
        height: 5px;
        width: 50px;
        background-color: #0088ea;
      }
    }
  }
  .partners_img {
    padding: 0;
    display: flex;
    width: 1490px;
    height: 600px;
    flex-wrap: wrap;
    margin: 10px auto;
    justify-content: space-between;
    li {
      width: 260px;
      height: 74px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // .partners_img1 {
    //   background-image: url("../assets/images/01首页/腾讯1.png");
    // }
    // .partners_img1:hover {
    //   background-image: url("../assets/images/01首页/腾讯2.png");
    // }
    // .partners_img2 {
    //   background-image: url("../assets/images/01首页/巨人网络1.png");
    // }
    // .partners_img2:hover {
    //   background-image: url("../assets/images/01首页//巨人网络2.png");
    // }
    // .partners_img3 {
    //   background-image: url("../assets/images/01首页/盛大游戏1.png");
    // }
    // .partners_img3:hover {
    //   background-image: url("../assets/images/01首页/盛大游戏2.png");
    // }
    // .partners_img4 {
    //   background-image: url("../assets/images/01首页/莉莉丝游戏1.png");
    // }
    // .partners_img4:hover {
    //   background-image: url("../assets/images/01首页/莉莉丝游戏2.png");
    // }
    // .partners_img5 {
    //   background-image: url("../assets/images/01首页/玩友时代1.png");
    // }
    // .partners_img5:hover {
    //   background-image: url("../assets/images/01首页//玩友时代2.png");
    // }
  }
}
.caselist {
  width: 1468px;
  margin: 0 auto;
  ul {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      height: 360px;
      width: 360px;
      background-color: #e8eaf2;
      text-align: center;
      margin: 10px 0 !important;

      .casetit {
        height: 80px;
        line-height: 80px;
        font-size: 20px;
      }
      .caseimg {
        width: 100%;
        height: 280px;
        box-sizing: border-box;
        border: 1px solid gray;
        z-index: 222;
        img {
          width: 100%;
          box-sizing: border-box;
          border: 1px solid white;
          z-index: 1;
        }
      }
    }
    li:hover {
      cursor: pointer;
      .casetit {
        background-color: #0088ea;
        color: white;
        font-weight: bold;
      }
    }
  }
}
.news {
  height: 860px;
  background-color: #e8eaf2;
  .newslist {
    ul {
      width: 1380px;
      margin: 40px auto;
      display: flex;
      li {
        margin-right: 30px;
        background-color: white;
        width: 440px;
        height: 540px;
        display: flex;
        flex-direction: column;

        align-items: center;
        .newsimg {
          height: 340px;
          box-sizing: border-box;
          border: 1px solid gray;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid white;
          }
        }
        .newscon {
          margin-top: 25px;
          width: 370px;

          .newcon_tit {
            font-size: 18px;
            font-weight: bold;
          }
          .newcon_txt {
            margin-top: 19px;
            font-size: 16px;
            overflow: hidden;

            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        .newsinfo {
          margin-top: 20px;
          width: 370px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          div {
            font-size: 0.18rem;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .morenews {
    width: 240px;
    height: 60px;
    margin: 0 auto;
    background-color: #0088ea;
    font-size: 24px;
    color: white;
    text-align: center;
    line-height: 60px;
  }
  .morenews:hover {
    background-color: #047acf;
    cursor: pointer;
  }
}
.sport {
  height: auto;

  .sportswiper {
    width: 1450px;
    height: 500px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    .swiper-button-prev-diy,
    .swiper-button-next-diy {
      width: 26px;
      height: 26px;
      position: absolute;
      bottom: 50px;

      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
    }
    .swiper-button-prev-diy {
      left: 580px;

      z-index: 9999;
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain;
    }
    .swiper-button-prev-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain;
    }
    .swiper-button-next-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain;
    }
    .swiper-button-next-diy {
      z-index: 9999;
      right: 570px;
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain;
    }
    .swiper_con {
      cursor: pointer;
      background-color: #e8eaf2;
      width: 460px;
      height: 385px;
      border: 2px solid #0088ea;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      img {
        width: 100%;
      }
      span {
        height: 85px;
        line-height: 85px;
        font-size: 18px;
        color: #000000;
      }
      &:hover {
        background-color: #0088ea;
      }
    }
  }
  .swiper_tit {
    width: 1456px;
    margin: -40px auto;
    ul {
      padding: 0;
      display: flex;
      justify-content: space-between;
      margin-bottom: 57px;
      margin-top: 44px;
      li {
        cursor: pointer;
        width: 488px;
        height: 60px;
        background-color: #e5f3fd;
        color: #000000;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          margin-right: 18px;
        }
        .swiper_img1 {
          width: 30px;
          height: 20px;
          background: url("~@/assets/images/01首页/产品系统2.png") no-repeat;
        }
        .swiper_img2 {
          width: 30px;
          height: 34px;
          background: url("~@/assets/images/01首页/解决方案1.png") no-repeat;
        }
        .swiper_img3 {
          width: 30px;
          height: 30px;
          background: url("~@/assets/images/01首页/项目案例1.png") no-repeat;
        }
      }
      .blue {
        background-color: #0088ea;
        cursor: pointer;
        color: white;
        .swiper_img1 {
          background: url("~@/assets/images/01首页/产品系统1.png") no-repeat;
        }
        .swiper_img2 {
          background: url("~@/assets/images/01首页/解决方案 2.png") no-repeat;
        }
        .swiper_img3 {
          background: url("~@/assets/images/01首页/项目案例2.png") no-repeat;
        }
      }
    }
  }
}
.cases {
  .product_title {
    margin-top: 6px;
    margin-bottom: 10px;
  }
  .caselist {
    width: 1456px;
    margin: 0 auto;

    ul {
      width: 100%;
      margin-bottom: 100px;
      margin-top: -10px;
      display: flex;
      flex-wrap: wrap;
      li {
        height: 378px;
        width: 460px;
        background-color: #e8eaf2;
        text-align: center;
        margin-right: 35px;
        margin-top: 30px;

        .casetit {
          height: 78px;
          line-height: 78px;
          font-size: 20px;
        }
        .caseimg {
          width: 100%;
          height: 300px;
          box-sizing: border-box;
          border: 1px solid gray;
          z-index: 222;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid white;
            z-index: 1;
          }
        }
      }
      li:hover {
        cursor: pointer;
        .casetit {
          background-color: #0088ea;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
  .pagination {
    width: 320px;
    margin: 0px auto;
    margin-top: -50px;
    margin-bottom: 50px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page {
      width: 30%;
      height: 26px;

      ul {
        padding: 0;
        margin: 0;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        li {
          cursor: pointer;

          width: 10px;
          height: 10px;
          background-color: #cccccc;
          border-radius: 50%;
        }
      }
      .hover {
        background-color: #666666;
      }
    }
    .prevbtn,
    .nextbtn {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    .prevbtn {
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain !important;
    }
    .nextbtn {
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain !important;
    }
    .prevbtn:hover {
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain !important;
    }
    .nextbtn:hover {
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain !important;
    }
  }
}
.xuni {
  .caselist {
    width: 1456px;
    margin: 0 auto;

    ul {
      width: 100%;
      margin-bottom: 80px;
      margin-top: -10px;
      display: flex;
      flex-wrap: wrap;
      li {
        height: 378px;
        width: 460px;
        background-color: #e8eaf2;
        text-align: center;
        margin-right: 35px;
        margin-top: 30px;

        .casetit {
          height: 78px;
          line-height: 78px;
          font-size: 20px;
        }
        .caseimg {
          width: 100%;
          height: 300px;
          box-sizing: border-box;
          border: 1px solid gray;
          z-index: 222;
          img {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid white;
            z-index: 1;
          }
        }
      }
      li:hover {
        cursor: pointer;
        .casetit {
          background-color: #0088ea;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
  .pagination {
    width: 320px;
    height: 26px;
    margin: 0 auto;
    margin-top: -40px;
    margin-bottom: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page {
      width: 30%;
      height: 26px;
      ul {
        padding: 0;
        margin: 0;
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        li {
          cursor: pointer;

          width: 10px;
          height: 10px;
          background-color: #cccccc;
          border-radius: 50%;
        }
      }
      .hover {
        background-color: #666666;
      }
    }
    .prevbtn,
    .nextbtn {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
    .prevbtn {
      background: url("~@/assets/images/01首页/上一页1.png");
      background-size: contain !important;
    }
    .nextbtn {
      background: url("~@/assets/images/01首页/下一页1.png");
      background-size: contain !important;
    }
    .prevbtn:hover {
      background: url("~@/assets/images/01首页/上一页2.png");
      background-size: contain !important;
    }
    .nextbtn:hover {
      background: url("~@/assets/images/01首页/下一页2.png");
      background-size: contain !important;
    }
  }
}
</style>